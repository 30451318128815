import { MutableRefObject } from 'react';
import { type FieldArrayRenderProps } from 'react-final-form-arrays';
import { isFalsy } from '@leapfinance/frontend-commons';
import { type OPTION_TYPE } from '@leapfinance/frontend-commons/schema/common';
import { isValid } from 'date-fns';
import format from 'date-fns/format';

import {
  GetStudentAcademicInfoResponseType,
  UpdateExamInfoRequestType,
  UpdateStudentAcademicDetailsRequestType,
} from '@/types/Services';
import { fixDateForAllBrowsers } from '@/utils/constants/common';
import { AcademicSchemaType } from '@/validations/studentProfile.schema';

export type BaseAcademicSectionProps = {
  name: string;
  editable: boolean;
  fieldRef: MutableRefObject<ArrayField>;
  studentId: number;
  onRemove: (index: number, fieldRef: MutableRefObject<ArrayField>) => void;
};
export type QualificationType = Exclude<
  Exclude<
    GetStudentAcademicInfoResponseType[`data`],
    undefined
  >[`highestEducationalQualification`],
  undefined
>;

export type ExamType = Exclude<
  Exclude<
    UpdateExamInfoRequestType[`examInfoDetails`],
    undefined
  >[number][`examType`],
  undefined
>;

export const additionalExams: ExamType[] = [`GRE`, `GMAT`];

export type ArrayField =
  | FieldArrayRenderProps<any, HTMLElement>[`fields`]
  | null;
export enum ProfileSections {
  PERSONAL = `profileInfo`,
  ACADEMIC = `academicInfo`,
  TEST = `test`,
  WORK = `workExperience`,
  DOCUMENTS = `uploadAllDocs`,
  OTHERS = `others`,
}

export type AcademicInfoDetailsType = Exclude<
  UpdateStudentAcademicDetailsRequestType[`academicInfoDetails`],
  undefined
>;
const AcademicExams = [
  `BACHELORS_DEGREE`,
  `MASTERS_DEGREE`,
  `PHD`,
  `PG_DIPLOMA`,
  `DIPLOMA`,
  `TENTH`,
  `TWELFTH`,
] as AcademicInfoDetailsType[number][`courseType`][];

export interface BaseSection {
  studentId: number;
  onFormStateChange: (section: ProfileSections, state: boolean) => void;
}

export const yesNoOptions = [
  { label: `Yes`, value: true },
  { label: `No`, value: false },
];

export const givenOptions = [
  { label: `Given`, value: true },
  { label: `Not Given`, value: false },
];
export const given = givenOptions.find((option) => option.value === true);
export const notGiven = givenOptions.find((option) => option.value === false);
export const yesOption = yesNoOptions.find((option) => option.value === true);
export const noOption = yesNoOptions.find((option) => option.value === false);

export const academicToFormNameMapping: Record<
  string,
  keyof AcademicSchemaType
> = {
  DIPLOMA: `diploma`,
  PG_DIPLOMA: `pgDiploma`,
  MASTERS_DEGREE: `masters`,
  TENTH: `tenth`,
  TWELFTH: `twelfth`,
  BACHELORS_DEGREE: `bachelors`,
  PHD: `phd`,
};
export const isOptionType = (option: any): option is OPTION_TYPE => {
  return (
    (option as OPTION_TYPE)?.value !== undefined &&
    (option as OPTION_TYPE)?.label !== undefined
  );
};

export const transformOptionValues = (obj: Record<string, any>) => {
  if (!obj) return obj;
  if (isOptionType(obj)) return obj.value;
  if (
    typeof obj === `number` ||
    typeof obj === `string` ||
    typeof obj === `boolean`
  ) {
    if (obj === `true`) return true;
    if (obj === `false`) return false;
    return obj;
  }
  const transformedValues: any = {};
  Object.entries(obj).forEach(([key, value]: [key: string, value: any]) => {
    if (isOptionType(value)) {
      transformedValues[key] = value.value;
    } else if (Array.isArray(value)) {
      transformedValues[key] = value.map((data) => transformOptionValues(data));
    } else if (typeof value === `string`) {
      transformedValues[key] =
        value.trim().length > 0 ? value.trim() : undefined;
    } else if (typeof value === `object`) {
      transformedValues[key] = transformOptionValues(value);
    } else {
      transformedValues[key] = value;
    }
  });

  return transformedValues;
};

export const displayStringValues = (
  value: string | null | undefined | number,
) => {
  if (typeof value === `number`) return value;
  if (!value || !value.trim()) return `-`;
  return value.trim();
};

export const displayOptionValues = (value: OPTION_TYPE | null | undefined) => {
  if (isOptionType(value)) return value?.label;
  if (!value) return `-`;
  return value;
};

export const displayOptionValuesArray = (
  value: OPTION_TYPE[] | null | undefined,
) => {
  if (isFalsy(value)) return `-`;
  if (Array.isArray(value))
    return value.map((option) => option.label)?.join(`, `);
};

export const displayDateValues = (value: string) => {
  try {
    if (!value) return `-`;
    const stableDate = fixDateForAllBrowsers(value);
    if (!isValid(new Date(stableDate))) return `-`;
    const date = format(new Date(stableDate), `dd/MM/yyyy`);
    return date;
  } catch (error) {
    console.error(`Failed to get valid date`, error);
    return `-`;
  }
};

export const getFormNames = <T extends Record<any, any>>(
  obj: T,
  prefix: string,
  seperator: string,
): Record<keyof T, string> => {
  const formNamesWithPrefix: Record<keyof T, string> = {} as Record<
    keyof T,
    string
  >;

  Object.entries(obj).forEach(([key, value]) => {
    formNamesWithPrefix[key as keyof T] = prefix + seperator + value;
  });

  return formNamesWithPrefix;
};
type AcademicQualification = Exclude<
  Exclude<
    GetStudentAcademicInfoResponseType[`data`],
    undefined
  >[`highestEducationalQualification`],
  undefined
>;
export const examTypeToExamFormKeys: Record<
  AcademicQualification,
  keyof AcademicSchemaType | ``
> = {
  TENTH: `tenth`,
  TWELFTH: `twelfth`,
  DIPLOMA: `diploma`,
  PG_DIPLOMA: `pgDiploma`,
  BACHELORS_DEGREE: `bachelors`,
  MASTERS_DEGREE: `masters`,
  PHD: `phd`,
  WORK_EXPERIENCE: ``,
  IELTS: ``,
  TOEFL: ``,
  PTE: ``,
  DUOLINGO: ``,
  GRE: ``,
  GMAT: ``,
  RESUME: ``,
  MISCELLANEOUS: ``,
  PASSPORT: ``,
  PAYMENT_PROOFS: ``,
};

export const academicExamsRanking: Record<
  (typeof AcademicExams)[number],
  number
> = {
  TENTH: 7,
  TWELFTH: 6,
  DIPLOMA: 5,
  PG_DIPLOMA: 4,
  BACHELORS_DEGREE: 3,
  MASTERS_DEGREE: 2,
  PHD: 1,
  RESUME: 0,
  WORK_EXPERIENCE: 0,
  IELTS: 0,
  TOEFL: 0,
  PTE: 0,
  DUOLINGO: 0,
  GRE: 0,
  GMAT: 0,
  MISCELLANEOUS: 0,
  PASSPORT: 0,
  PAYMENT_PROOFS: 0,
};
export const qualificationsOrder = {
  PHD: 1,
  MASTERS_DEGREE: 2,
  BACHELORS_DEGREE: 4,
  PG_DIPLOMA: 3,
  DIPLOMA: 5,
  TWELFTH: 6,
  TENTH: 7,
};

export const getNextQualifications = (
  selectedQualification: AcademicQualification,
  existingQualifications: AcademicQualification[],
) => {
  const qualificationKeys = Object.keys(qualificationsOrder);
  const selectedQualificationIndex = qualificationKeys.indexOf(
    selectedQualification,
  );

  if (selectedQualificationIndex === -1) {
    // Invalid qualification, return an empty array
    return [];
  }

  const nextQualifications = [];

  for (let i = selectedQualificationIndex; i < qualificationKeys.length; i++) {
    const qualification = qualificationKeys[i];
    if (
      !existingQualifications.includes(qualification as AcademicQualification)
    ) {
      nextQualifications.push(qualification);
      if (nextQualifications.length === 2) {
        break;
      }
    }
  }

  return nextQualifications;
};

export const microsoftDocuments = `.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document`;

export const sectionToNameMapping: Record<ProfileSections, string> = {
  profileInfo: `Profile Info`,
  academicInfo: `Academic Info`,
  test: `Test Info`,
  workExperience: `Work Experience`,
  uploadAllDocs: `Upload All Docs`,
  others: `Others`,
};

export const gapInStudiesOptions = [
  { label: `0`, value: `0` },
  { label: `1`, value: `1` },
  { label: `2`, value: `2` },
  { label: `3`, value: `3` },
  { label: `4`, value: `4` },
  { label: `5`, value: `5` },
  { label: `6`, value: `6` },
  { label: `7`, value: `7` },
  { label: `8`, value: `8` },
  { label: `9`, value: `9` },
  { label: `10`, value: `10` },
];

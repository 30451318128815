import { useState } from 'react';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { useSnackbar } from 'notistack';

import {
  useDeleteDocumentMutation,
  useGetPresignedUrlMutation,
} from '@/services/documents';
import { useUploadAndSaveMutation } from '@/services/documentUpload';
import { definitions } from '@/types/schema';
import { FileUploadResponse } from '@/types/Services';
import { confirmDialog } from '@/utils/confirmDialog';
import { downloadFileFromPresignedUrl } from '@/utils/download';

export interface HandleDownloadDocumentParams {
  keyName: string;
  id: string;
  onDownloadDocumentSuccess?: () => void;
}

export interface HandleDeleteDocumentParams {
  confirmationRequired?: boolean;
  keyName: string;
  id: number;
  onDeleteDocumentSuccess?: () => void;
}

export interface HandleViewDocumentParams {
  keyName: string;
  id: string;
  fileName: string;
}

export interface HandlePresignedUrlDownloadParams {
  url: string;
  onDownloadUrlSuccess?: () => void;
  onDownloadUrlFailure?: () => void;
  downloadInPlace?: boolean;
  downloadFileName?: string;
  downloadInPlaceViaUrl?: boolean;
}

export interface HandleUploadDocumentParams {
  file: File;
  fileName: string;
  studentId?: number | null;
  applicationId?: number | null;
  callback?: (success: boolean, data?: any) => void;
  onUploadDocumentSuccess?: (data?: definitions['DocumentDto']) => void;
  onUploadDocumentFailure?: () => void;
}

function useDocumentActions() {
  const [isDocumentDownloading, setIsDocumentDownloading] = useState(false);
  const [getPresignedUrlTrigger] = useGetPresignedUrlMutation();
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDocumentTrigger] = useDeleteDocumentMutation();
  const [uploadAndSaveTrigger] = useUploadAndSaveMutation();

  const handleDownloadDocument = async ({
    keyName,
    id,
    onDownloadDocumentSuccess,
  }: HandleDownloadDocumentParams) => {
    try {
      if (!id || !keyName)
        throw new Error(`Either keyname or id is missing for the file`);
      setIsDocumentDownloading(true);
      // cannot use RTK Query for file downloads so using axios directly
      const res = await axios.get(
        `/api/download?path=/documents/download/${keyName}/${id}`,
        {
          responseType: `blob`,
        },
      );
      if (res && res.data && res.data.size > 0) {
        const fileName =
          res.headers[`content-disposition`].split(`filename=`)[1];
        saveAs(res.data, fileName);
        onDownloadDocumentSuccess?.();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsDocumentDownloading(false);
    }
  };

  const handlePresignedUrlDownload = ({
    url,
    onDownloadUrlSuccess,
    onDownloadUrlFailure,
    downloadInPlace,
    downloadFileName,
    downloadInPlaceViaUrl,
  }: HandlePresignedUrlDownloadParams) => {
    if (!url) {
      enqueueSnackbar(
        `Oops, something went wrong. Please try downloading the document.`,
        { variant: `error` },
      );
      return;
    }
    getPresignedUrlTrigger({ url })
      .unwrap()
      .then(async (data) => {
        if (!data.success || !data?.data) {
          onDownloadUrlFailure?.();
          return;
        }
        if (data?.data) {
          if (downloadInPlace) {
            saveAs(data?.data, downloadFileName);
          } else {
            downloadFileFromPresignedUrl({
              href: data.data,
              ...(downloadInPlaceViaUrl
                ? {
                    target: `_self`,
                  }
                : {
                    target: `_blank`,
                  }),
            });
          }
          onDownloadUrlSuccess?.();
        }
      });
  };

  const handleDeleteDocument = ({
    keyName,
    id,
    confirmationRequired = true,
    onDeleteDocumentSuccess,
  }: HandleDeleteDocumentParams) => {
    if (confirmationRequired) {
      confirmDialog({
        title: `Document Delete Action`,
        message: `Are you sure you want to delete this document?`,
        onSubmit: () => {
          deleteDocumentTrigger({ keyName, documentId: id })
            .unwrap()
            .then(() => {
              onDeleteDocumentSuccess?.();
            });
        },
      });
    } else {
      deleteDocumentTrigger({ keyName, documentId: id })
        .unwrap()
        .then(() => {
          onDeleteDocumentSuccess?.();
        });
    }
  };

  const handleUploadDocument = async ({
    file,
    fileName,
    studentId,
    applicationId,
    callback,
    onUploadDocumentSuccess,
    onUploadDocumentFailure,
  }: HandleUploadDocumentParams) => {
    await uploadAndSaveTrigger({
      file: file,
      applicationId: applicationId ?? undefined,
      studentId: studentId || 0,
      key: fileName,
    })
      .unwrap()
      .then((res: FileUploadResponse) => {
        if (res && res.success) {
          onUploadDocumentSuccess?.(res?.data as definitions['DocumentDto']);
          callback?.(res.success, res?.data);
        } else {
          onUploadDocumentFailure?.();
        }
      })
      .catch(() => {
        onUploadDocumentFailure?.();
      });
  };

  return {
    isDocumentDownloading,
    handleDownloadDocument,
    handlePresignedUrlDownload,
    handleDeleteDocument,
    handleUploadDocument,
  };
}

export default useDocumentActions;

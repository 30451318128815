import React, { useEffect } from 'react';
import { updateConfig, updateLoading } from 'app/features/user/userSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectRole, updateRole } from 'features/generalSlice';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { resetLocalStorageFilterData } from 'utils/components/SummaryDashboard';

import { TASK_FILTERS_CLIENT_STORAGE_KEY } from '@/lib/constant';
import { Roles } from '@/types/global';
import { HEADER_HEIGHT } from '@/utils/constants/common';
import { getUserName } from 'components/Application/ApplicationApis';

import NotificationDropDown from './NotificationDropDown';
import ProfileDropDown from './ProfileDropDown';

const NewHeader = () => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const role: string | null = useAppSelector((state) => selectRole(state));

  useEffect(() => {
    (async () => {
      try {
        dispatch(updateLoading(true));
        const data = await getUserName();
        const { userType } = data;
        if (!role) {
          dispatch(updateRole(userType));
          dispatch(updateLoading(false));
        }
        dispatch(updateConfig(data));
        dispatch(updateLoading(false));
      } catch (error: any) {}
    })();
  }, []);

  return (
    <div
      id="navigation-header"
      className={`flex justify-between  px-12 shadow-md h-[${HEADER_HEIGHT}px]`}
    >
      {/* logo */}
      <div
        className="md:h-[40] h-[20] md:py-1 py-2 flex items-center cursor-pointer"
        onClick={() => router.replace(`/dashboard/applications`)}
      >
        <Image
          src={`/assets/icons/logo.png`}
          alt="/assets/icons/logo.png"
          width="130"
          height="40"
        />
      </div>
      {/* tabs */}
      <div className="md:flex font-bold text-lg hidden">
        <p
          onClick={() => {
            resetLocalStorageFilterData(`summary-dashboard-filters-1`);
            resetLocalStorageFilterData(TASK_FILTERS_CLIENT_STORAGE_KEY);
            if (role === Roles.admin) {
              router.push(`/dashboard/admin`);
            } else {
              router.push(`/dashboard/summary`);
            }
          }}
          className={` h-full cursor-pointer mx-4 flex items-center box-content ${
            router.pathname.toString() === `/dashboard/summary` &&
            `border-b-primary-500 border-b-4 text-primary-500`
          }`}
        >
          Dashboard
        </p>
        <p
          onClick={() => {
            resetLocalStorageFilterData(TASK_FILTERS_CLIENT_STORAGE_KEY);
            router.push(`/course-finder`);
          }}
          className={` h-full cursor-pointer mx-4 flex items-center box-content ${
            router.pathname.toString() === `/course-finder` &&
            `border-b-primary-500 border-b-4 text-primary-500`
          }`}
        >
          Course Finder
        </p>
        <p
          onClick={() => {
            resetLocalStorageFilterData(`summary-dashboard-filters-1`);
            resetLocalStorageFilterData(TASK_FILTERS_CLIENT_STORAGE_KEY);
            router.push(`/dashboard/applications`);
          }}
          className={` h-full cursor-pointer mx-4 flex items-center box-content ${
            router.pathname.toString() === `/dashboard/applications` &&
            `border-b-primary-500 border-b-4 text-primary-500`
          }`}
        >
          Applications
        </p>
        <p
          onClick={() => {
            resetLocalStorageFilterData(`summary-dashboard-filters-1`);
            resetLocalStorageFilterData(TASK_FILTERS_CLIENT_STORAGE_KEY);
            router.push(`/dashboard/students`);
          }}
          className={` h-full cursor-pointer mx-4 flex items-center box-content ${
            router.pathname.toString() === `/dashboard/students` &&
            `border-b-primary-500 border-b-4 text-primary-500`
          }`}
        >
          Students
        </p>
      </div>
      <div className="flex items-center">
        {/* notification */}
        <div className="md:flex hidden">
          <NotificationDropDown />
        </div>
        {/* profile dropdown */}
        <ProfileDropDown />
      </div>
    </div>
  );
};

export default NewHeader;

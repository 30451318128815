import React, { useRef } from 'react';
import { Field, Form } from 'react-final-form';
import { isFalsy } from '@leapfinance/frontend-commons';
import {
  FFAsyncDropdown,
  FFDropdown,
  FFMultiSelectDropdown,
  GBModalWrapper,
  OptionType,
  YearAndMonthPicker,
} from '@leapfinance/geebee-component-library';
import { format } from 'date-fns';
import { FormApi } from 'final-form';
import { debounce } from 'lodash';
import { useSnackbar } from 'notistack';

import { selectGeebeeList } from '@/app/features/geebeeSharedList/geebeeSharedListSlice';
import { useAppSelector } from '@/app/hooks';
import { useGetUniversitySuggestionsMutation } from '@/services/applicationApi';
import { useAddFocusedInstitutesMutation } from '@/services/focusedInstitute/service';
import { useGetUserCountryGroupsQuery } from '@/services/generalApi';
import { isValidNumber } from '@/utils/common';
import { getSharedFilterValueOptions } from '@/utils/helperFunctions';

import { useGetUniversityCoursesMutation } from '../CF/University/universityService';
import useModal from '../modals/useModal';

import { AddFocusedInstitutePayload } from './type';

export interface AddFocusedUniversityFormProps {
  afterAddingInstitute?: () => void;
}
function AddFocusedUniversityForm({
  afterAddingInstitute,
}: AddFocusedUniversityFormProps) {
  const { data } = useGetUserCountryGroupsQuery();
  const { hideModal } = useModal();
  const [triggerCollegeSearch] = useGetUniversitySuggestionsMutation();
  const [triggerAddFocusedInstitute, { isLoading }] =
    useAddFocusedInstitutesMutation();
  const { enqueueSnackbar } = useSnackbar();

  const formRef = useRef<FormApi<any> | null>(null);

  const sharedList = useAppSelector((state) => selectGeebeeList(state));
  const usps = getSharedFilterValueOptions(`universityUsps`, sharedList);
  const [triggerUniversityCoursesSearch, { data: universityCourseData }] =
    useGetUniversityCoursesMutation();

  const countries = data?.data?.map((country) => ({
    label: country.labelValue,
    value: country.cfCountryId,
    countryValue: country?.labelKey,
  }));

  const fetchUniversities = async (
    inputValue: string,
    callback: any,
    countryId: number,
  ) => {
    if (!countryId || !isValidNumber(countryId)) return [];
    const options = [];
    const data = await triggerCollegeSearch({
      universityName: inputValue,
      countryIds: [countryId],
      showOnlyTieUpInstitutes: true,
    })?.unwrap();
    if (!isFalsy(data?.data?.universities)) {
      const list = data?.data?.universities || [];
      for (let i = 0; i < list.length; i++) {
        options.push({
          value: list[i]?.universityId,
          label: list[i]?.universityName,
        });
      }
    }
    callback(options);
  };

  const debounceLoadUniversityOptions = debounce(fetchUniversities, 500);

  const handleUniversityChange = async (value: OptionType) => {
    await triggerUniversityCoursesSearch({
      collegeIds: [value?.value],
      totalPerPage: 1,
      pageIndex: 1,
    });
  };

  const handleSubmit = (values: any) => {
    const payload: AddFocusedInstitutePayload = {
      country: values?.country?.countryValue,
      universityId: values?.university?.value,
      usp: values?.usps?.map((usp: { value: any }) => usp?.value) ?? [],
      endDate: format(
        new Date(values?.validTill?.year, values?.validTill?.month + 1, 0),
        `yyyy-MM-dd`,
      ),
      universityName: values?.university?.label,
    };
    triggerAddFocusedInstitute(payload)
      ?.unwrap()
      ?.then((data) => {
        if (data?.success) {
          enqueueSnackbar(`Successfully added Institute`, {
            variant: `success`,
          });
          afterAddingInstitute?.();
          hideModal();
        }
      });
  };

  const universityHasZeroCount =
    !isFalsy(universityCourseData) &&
    Math.max(
      universityCourseData?.data?.matchedResults?.total ?? 0,
      universityCourseData?.data?.otherResults?.total ?? 0,
    ) <= 0;

  return (
    <GBModalWrapper
      variant="medium"
      headerProps={{
        onClose: hideModal,
        title: `Add Focused Institute`,
      }}
      footerProps={{
        primaryCta: {
          title: `Submit`,
          buttonProps: {
            onClick: () => formRef?.current?.submit(),
            loading: isLoading,
            disabled: isLoading,
          },
        },
      }}
    >
      <div className="p-4">
        <Form
          onSubmit={handleSubmit}
          render={({ values, form }) => {
            formRef.current = form;

            return (
              <div className="flex flex-col gap-4 ">
                <FFDropdown
                  fieldProps={{
                    name: `country`,
                    validate: (value) =>
                      isFalsy(value) ? `Country is required` : undefined,
                  }}
                  componentProps={{
                    options: countries,
                    textInputProps: {
                      label: `Country`,
                      required: true,
                      placeholder: `Select country`,
                    },
                    onChange: () => {
                      form.change(`university`, undefined);
                    },
                  }}
                />
                <FFAsyncDropdown
                  fieldProps={{
                    name: `university`,
                    validate: (value) =>
                      isFalsy(value) ? `University is required` : undefined,
                  }}
                  componentProps={{
                    options: [],
                    textInputProps: {
                      label: `University`,
                      required: true,
                      placeholder: `Search University`,
                      disabled: isFalsy(values?.country),
                      helperText:
                        universityHasZeroCount && !isFalsy(values?.university)
                          ? `This university has 0 courses`
                          : ``,
                    },

                    fetchOptionsHandler: (request, callback) =>
                      debounceLoadUniversityOptions(
                        request?.input,
                        callback,
                        values?.country?.value,
                      ),
                    onChange: (_, value) => {
                      handleUniversityChange(value as unknown as OptionType);
                    },
                  }}
                />
                <FFMultiSelectDropdown
                  fieldProps={{
                    name: `usps`,
                    validate: (value) =>
                      isFalsy(value) ||
                      (Array.isArray(value) && value.length !== 3)
                        ? `3 USPs are required`
                        : ``,
                  }}
                  componentProps={{
                    options: usps,
                    maxRowsToBeSelected: 3,
                    warningLabel: `3 USPs selected`,
                    textInputProps: {
                      label: `USPs`,
                      placeholder: `Select USPs`,
                      helperText: `Select 3 USPs`,
                    },
                  }}
                />
                <Field
                  name="validTill"
                  validate={(value) => {
                    if (
                      isFalsy(value) ||
                      isFalsy(value?.month) ||
                      isFalsy(value?.year)
                    )
                      return `Valid for month is required`;
                    const currentDate = new Date();
                    const chosenDate = new Date();
                    chosenDate?.setMonth(value?.month);
                    chosenDate?.setFullYear(value?.year);

                    if (chosenDate < currentDate) {
                      return `Valid for month cannot be a past date`;
                    }
                  }}
                  render={({ input, meta }) => {
                    return (
                      <div className="bg-white">
                        <YearAndMonthPicker
                          minDate={new Date()}
                          value={input?.value}
                          textInputProps={{
                            error: meta?.touched && meta?.error,
                            helperText: meta?.touched ? meta?.error : undefined,
                            onBlur: input?.onBlur,
                            onFocus: input?.onFocus,
                            label: `Valid for month`,
                            placeholder: `Please select month and year`,
                            size: `small`,
                            sx: {
                              '& .MuiOutlinedInput-root': {
                                height: `48px`,
                              },
                            },
                            required: true,
                          }}
                          onChange={(value) => input.onChange(value)}
                          popperProps={{ placement: `top` }}
                        />
                      </div>
                    );
                  }}
                />
              </div>
            );
          }}
        />
      </div>
    </GBModalWrapper>
  );
}

export default AddFocusedUniversityForm;

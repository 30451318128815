import { isFalsy } from '@leapfinance/frontend-commons';
import { cfSearchFilters } from '@leapfinance/frontend-commons/components/courseFinder/constants';
import { type OPTION_TYPE } from '@leapfinance/frontend-commons/schema/common';
import { InputDataProp } from 'types/common';

import { SharedLists } from '@/app/features/sharedList/sharedListSlice';
import {
  DEGREE_OPTIONS,
  POPULAR_COUNTRIES,
} from '@/components/CF/Dashboard/constants';
import { ApplicationStages } from '@/lib/constant';
import { definitions } from '@/types/schema';

export const regenerateLink = (link: string) => {
  if (link?.includes(`http`)) {
    return link;
  } else {
    return `http://${link}`;
  }
};
export const urlRedirect = (url: string) => (location.href = url);

export const readableStr = (string: any) => {
  if (!string) return;
  const str = string.toString() as any;
  let i = null;
  const frags = str.split(`_`);
  for (i = 0; i < frags.length; i++) {
    frags[i] =
      frags[i].charAt(0).toUpperCase() + frags[i].slice(1).toLowerCase();
  }
  return frags.join(` `);
};
export const GRADING_SCHEME = {
  CGPA: `CGPA`,
  PERCENTAGE: `PERCENTAGE`,
  ZERO_TO_FOUR: `ZERO_TO_FOUR`,
};
export const dummyFunction = () => {};

export const getEditableField = (inputData: InputDataProp) => {
  const { InputElement, editStatus, inputValue } = inputData;
  if (!editStatus) {
    return inputValue ? (
      <div
        className="w-full my-3 truncate"
        title={inputValue.toString().length > 30 ? inputValue.toString() : ``}
      >
        {inputValue.toString()}
        <div className="hidden">{InputElement}</div>
      </div>
    ) : (
      <div className="w-full min-h-[40px]">{` ${` `}`}</div>
    );
  } else return <div className="w-full">{InputElement}</div>;
};

export const getSharedListOptions = (
  optionName: any,
  sharedList: any,
): { label: any; value: any }[] => {
  return (sharedList[optionName] || []).map((option: any) => ({
    label: option.labelValue,
    value: option.labelKey,
  }));
};

export const removeQueryParamsFromUrl = () => {
  // Get the current URL
  const url = window.location.href;

  // Remove the query parameters from the URL
  const newUrl = url.split(`?`)[0];

  // Change the URL without refreshing the page
  window.history.pushState({ path: newUrl }, ``, newUrl);
};

export const isOptionType = (option: any): option is OPTION_TYPE => {
  return (
    (option as OPTION_TYPE)?.value !== undefined &&
    (option as OPTION_TYPE)?.label !== undefined
  );
};

export const getHigherEducationOptions = (sharedList: SharedLists) => {
  return {
    degree:
      (sharedList?.degree ?? []).map(({ labelKey, labelValue }) => ({
        label: labelValue || ``,
        value: labelKey || ``,
      })) || [],
    specialization:
      (sharedList?.specialisation ?? []).map(({ labelKey, labelValue }) => ({
        label: labelValue || ``,
        value: labelKey || ``,
      })) || [],
    gradeSystem:
      (sharedList?.gradeSystem ?? []).map(({ labelKey, labelValue }) => ({
        label: labelValue || ``,
        value: labelKey || ``,
      })) || [],
  };
};
export interface studentEligibilityFormValuesProp {
  highestEducation?: string | null;
  preferredField?: string | null;
  englishGrades?: number | null;
  overallGrades?: number | null;
  studentAcademicPreference?: string | null;
  englishExamGiven?: boolean | null;
  englishExamType?: OPTION_TYPE | null;
  englishExamMinBand?: string | null;
  englishExamTotalScore?: string | null;
  degree?: OPTION_TYPE | null;
  specialization?: OPTION_TYPE | null;
  ugGradingScheme?: OPTION_TYPE | null;
  ugGrades?: string | null;
  pgGradingScheme?: OPTION_TYPE | null;
  pgGrades?: number | null;
  totalBackLogs?: number | null;
  totalYearOfEducation?: number | null;
  totalYearsWorkExperience?: number | null;
  studentWorkPreference?: string | null;
  higherExamType?: OPTION_TYPE | null;
  higherExamTotalScore?: number | null;
  englishExamReadingScore?: string | null;
  englishExamWritingScore?: string | null;
  englishExamSpeakingScore?: string | null;
  englishExamListeningScore?: string | null;
}

export const StudentEligibilityCFPayload = (
  studentEligibilityFormValues: studentEligibilityFormValuesProp,
) => {
  return {
    academicBackground:
      [studentEligibilityFormValues?.specialization?.label] || null,
    backlog: Number(studentEligibilityFormValues?.totalBackLogs) || null,
    additionalExam: studentEligibilityFormValues?.higherExamType?.value || null,
    additionalExamScore:
      Number(studentEligibilityFormValues?.higherExamTotalScore) || null,
    englishExamType:
      studentEligibilityFormValues?.englishExamType?.value || null,
    englishExamScore:
      Number(studentEligibilityFormValues?.englishExamTotalScore) || null,
    englishExamMinBand:
      Number(studentEligibilityFormValues?.englishExamMinBand) || null,
    twelfth_score_100:
      Number(studentEligibilityFormValues?.overallGrades) || null,
    twelfthEnglishSubjectGrades:
      Number(studentEligibilityFormValues?.englishGrades) || null,
    ug_score_4:
      GRADING_SCHEME.ZERO_TO_FOUR ===
      studentEligibilityFormValues?.ugGradingScheme?.value
        ? Number(studentEligibilityFormValues?.ugGrades)
        : null,
    ug_score_10:
      GRADING_SCHEME.CGPA ===
      studentEligibilityFormValues?.ugGradingScheme?.value
        ? Number(studentEligibilityFormValues?.ugGrades)
        : null,
    ug_score_100:
      GRADING_SCHEME.PERCENTAGE ===
      studentEligibilityFormValues?.ugGradingScheme?.value
        ? Number(studentEligibilityFormValues?.ugGrades)
        : null,
    workExperienceInMonths:
      Number(studentEligibilityFormValues?.totalYearsWorkExperience) || null,
  };
};

// export const handleCourseFinderFunctionalityForStudentEligiblityCheck = async (
//   triggerGetEducationalDetails: (
//     params: GetStudentAcademicInfoRequestType,
//   ) => any,
//   triggerGetWorkExperienceDetails: (params: GetWorkInfoRequestType) => any,
//   triggerGetExamInfo: (params: GetExamInfoRequestType) => any,
//   studentId: number,
//   sharedList: definitions['FilterListDto'],
// ) => {
//   const academicInfo = await triggerGetEducationalDetails({
//     studentId: Number(studentId),
//   }).unwrap();
//   const workExperience = await triggerGetWorkExperienceDetails({
//     studentId: Number(studentId),
//   }).unwrap();
//   const examInfo = await triggerGetExamInfo({
//     studentId: Number(studentId),
//   }).unwrap();
//   const englishExamData = examInfo?.data?.examInfoDetails?.find(
//     (examDetail: definitions['StudentExamInfoDto']) =>
//       examDetail?.examType === examInfo?.data?.englishExamType,
//   );
//   const greExamData = examInfo?.data?.examInfoDetails?.find(
//     (examDetail: definitions['StudentExamInfoDto']) =>
//       examDetail?.examType === `GRE`,
//   );
//   const gmatExamData = examInfo?.data?.examInfoDetails?.find(
//     (examDetail: definitions['StudentExamInfoDto']) =>
//       examDetail?.examType === `GMAT`,
//   );
//   const ugExamData = academicInfo?.data?.academicInfoDetails?.find(
//     (academicDetail: definitions['AcademicInfoDto']) =>
//       academicDetail?.courseType === `BACHELORS_DEGREE`,
//   );
//   const pgExamData = academicInfo?.data?.academicInfoDetails?.find(
//     (academicDetail: definitions['AcademicInfoDto']) =>
//       academicDetail?.courseType === `MASTERS_DEGREE`,
//   );
//   const twelfthDiplomaData = academicInfo?.data?.academicInfoDetails?.find(
//     (academicDetail: definitions['AcademicInfoDto']) =>
//       academicDetail?.courseType === `TWELFTH` ||
//       academicDetail?.courseType === `DIPLOMA`,
//   );
//   const degreeOptions = getHigherEducationOptions(sharedList)?.degree;
//   const specializationOptions =
//     getHigherEducationOptions(sharedList)?.specialization;
//   const gradeOptions = getHigherEducationOptions(sharedList)?.gradeSystem;
//   const studentData: studentEligibilityFormValuesProp = {};
//   if (
//     academicInfo?.data?.highestEducationalQualification === `BACHELORS_DEGREE`
//   ) {
//     studentData.highestEducation = `bachelor`;
//     studentData.degree = degreeOptions?.find(
//       (degree) => ugExamData?.degree === degree?.value,
//     );
//     studentData.specialization = specializationOptions?.find(
//       (specialization) => ugExamData?.specialisation === specialization?.value,
//     );
//     studentData.ugGradingScheme = gradeOptions?.find(
//       (gradeSystem) => ugExamData?.gradeSystem === gradeSystem?.value,
//     );
//     studentData.ugGrades = ugExamData?.grade ? String(ugExamData?.grade) : null;
//     studentData.totalBackLogs = ugExamData?.backLogs;
//   }
//   if (
//     academicInfo?.data?.highestEducationalQualification === `MASTERS_DEGREE`
//   ) {
//     studentData.highestEducation = `master`;
//     studentData.degree = degreeOptions?.find(
//       (degree) => pgExamData?.degree === degree?.value,
//     );
//     studentData.specialization = specializationOptions?.find(
//       (specialization) => pgExamData?.specialisation === specialization?.value,
//     );
//     studentData.ugGradingScheme = gradeOptions?.find(
//       (gradeSystem) => ugExamData?.gradeSystem === gradeSystem?.value,
//     );
//     studentData.pgGradingScheme = gradeOptions?.find(
//       (gradeSystem) => pgExamData?.gradeSystem === gradeSystem?.value,
//     );
//     studentData.ugGrades = ugExamData?.grade ? String(ugExamData?.grade) : null;
//     studentData.pgGrades = pgExamData?.grade;
//     studentData.totalBackLogs = pgExamData?.backLogs;
//   }
//   if (
//     academicInfo?.data?.highestEducationalQualification === `TWELFTH` ||
//     academicInfo?.data?.highestEducationalQualification === `DIPLOMA`
//   ) {
//     studentData.highestEducation = `twelfth/diploma`;
//   }
//   if (examInfo?.data?.englishExamType) {
//     studentData.englishExamGiven = true;
//     studentData.englishExamType = {
//       label: examInfo?.data?.englishExamType,
//       value: examInfo?.data?.englishExamType,
//     };
//     studentData.englishExamTotalScore = englishExamData?.totalScore
//       ? String(englishExamData?.totalScore)
//       : null;
//     studentData.englishExamListeningScore = englishExamData?.listeningScore
//       ? String(englishExamData?.listeningScore)
//       : null;
//     studentData.englishExamReadingScore = englishExamData?.readingScore
//       ? String(englishExamData?.readingScore)
//       : null;
//     studentData.englishExamSpeakingScore = englishExamData?.speakingScore
//       ? String(englishExamData?.speakingScore)
//       : null;
//     studentData.englishExamWritingScore = englishExamData?.writingScore
//       ? String(englishExamData?.writingScore)
//       : null;
//     studentData.englishExamMinBand =
//       englishExamData &&
//       englishExamData?.listeningScore &&
//       englishExamData?.writingScore &&
//       englishExamData?.speakingScore &&
//       englishExamData?.readingScore
//         ? String(
//             Math.min(
//               englishExamData?.listeningScore,
//               englishExamData?.writingScore,
//               englishExamData?.speakingScore,
//               englishExamData?.readingScore,
//             ),
//           )
//         : null;
//   }
//   if (greExamData) {
//     studentData.higherExamType = { label: `GRE`, value: `GRE` };
//     studentData.higherExamTotalScore = greExamData.totalScore;
//   }
//   if (gmatExamData) {
//     studentData.higherExamType = { label: `GMAT`, value: `GMAT` };
//     studentData.higherExamTotalScore = gmatExamData.totalScore;
//   }
//   studentData.englishGrades = twelfthDiplomaData?.englishGrade;
//   studentData.overallGrades = twelfthDiplomaData?.grade;
//   studentData.totalYearsWorkExperience =
//     workExperience?.data?.totalWorkExperienceInMonths;
//   return studentData;
// };

export const getGlobalSearchValues = (
  studentEligbilityFilter: any,
  degreeOptions: OPTION_TYPE[],
) => {
  const filterData = JSON.parse(studentEligbilityFilter as string);

  const intakeOptions = cfSearchFilters?.find(
    (filterOption) => filterOption?.key === `INTAKE`,
  )?.options as OPTION_TYPE[];
  let filterIntake = filterData?.intake?.split(`_`);
  filterIntake = `${filterIntake?.[0].substring(0, 3)}_${filterIntake?.[1]}`;
  const chosenCountry = (POPULAR_COUNTRIES ?? []).find(
    (country) => country?.label === filterData?.country,
  );
  const chosenIntake = (intakeOptions ?? [])?.find((intake) =>
    String(intake?.value)?.includes(filterIntake),
  );

  const chosenDegree = filterData?.degree?.map((degreePreference: string) =>
    (degreeOptions ?? [])?.find((degree) => {
      return String(degree?.value) === degreePreference;
    }),
  );

  const searchValues: any = {
    countryIds: chosenCountry ? [chosenCountry] : [],
    intake: chosenIntake ? [chosenIntake] : [],
    degreeIdsV2: chosenDegree ? chosenDegree : [],
    shortlistIntake: filterIntake,
  };

  // if (filterData?.courseName) {
  //   searchValues.tagsRawSearch = {
  //     label: filterData?.courseName,
  //     value: filterData?.courseName,
  //   };
  //   searchValues.tags = {
  //     label: filterData?.courseName,
  //     value: filterData?.courseName,
  //   };
  // }

  return searchValues;
};

export const scrollToElementWithOffset = (id: string, headerOffset = 64) => {
  const element = document?.getElementById(id);
  const elementPosition = element?.getBoundingClientRect().top;
  const offsetPosition =
    (elementPosition || 0) + window.pageYOffset - headerOffset;

  scrollTo({
    top: offsetPosition,
    behavior: `smooth`,
  });
};

export const getSharedFilterValueOptions = (
  optionName: Exclude<keyof definitions['SpeedUpFilterDto'], 'intakeRange'>,
  sharedList?: definitions['SpeedUpFilterDto'],
): OPTION_TYPE[] => {
  return (sharedList?.[optionName] || []).map(
    (option) =>
      ({
        label: option.label,
        value: option.value,
      } as OPTION_TYPE),
  );
};

type GetShowPathwayProviderDocumentUploadChipParamsType = {
  currentStage: any;
  currentSelectedPathwayProvider: any;
};

export function getShowPathwayProviderDocumentUploadChip({
  currentStage,
  currentSelectedPathwayProvider,
}: GetShowPathwayProviderDocumentUploadChipParamsType) {
  return (
    currentStage === ApplicationStages.APPLICATION_SENT_TO_UNIVERSITY &&
    !isFalsy(currentSelectedPathwayProvider) &&
    currentSelectedPathwayProvider !== `DIRECT_UNIVERSITY_WEBSITE`
  );
}

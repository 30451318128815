import { store } from 'app/store';
import difference from 'lodash/difference';
import {
  PermissionsList,
  rolesPermissionMapping,
} from 'utils/userManagementPermissions';

import {
  ApplicationDashboardPermissionsList,
  ApplicationDashboardRolesPermissionMapping,
} from './applicationDashboardPermission';
import {
  PagePermissionsList,
  pageRolesPermissionMapping,
} from './pagePermissions';

enum Resource {
  pages = `PAGES`,
  userManagement = `USER_MANAGEMENT`,
  applicationDashboard = `APPLICATION`,
}

/**
 * This mapping is mainly used for suggesting developers with proper resource type and the permissions they offer while checking for permissions
 */
const resourcePermissionsMapping = {
  pages: PagePermissionsList,
  userManagement: PermissionsList,
  applicationDashboard: ApplicationDashboardPermissionsList,
};

/**
 * Mapping of resource with role permissions availablity
 */
const permissionResourceMapping = {
  pages: pageRolesPermissionMapping,
  userManagement: rolesPermissionMapping,
  applicationDashboard: ApplicationDashboardRolesPermissionMapping,
};

type hasPermissionProps<ResourceType extends keyof typeof Resource> =
  (keyof (typeof resourcePermissionsMapping)[ResourceType])[];

/**
 *
 * @param resource Permission resource type
 * @param permissions List of available permissions for the resource
 * @returns boolean
 */
const hasPermissionTo = <T extends keyof typeof Resource>(
  resource: T,
  permissions: hasPermissionProps<T>,
): boolean => {
  const permissionMapping = permissionResourceMapping[resource];
  const state = store.getState();
  if (!state || !state.user || !state.user.userConfig) return false;
  const {
    userConfig: { userType },
  } = state.user;

  return !difference(
    permissions.map((permission) =>
      Object.values(resourcePermissionsMapping[resource]).indexOf(
        permission as unknown as Resource,
      ),
    ),
    (permissionMapping as any)[userType as any] as any,
  ).length;
};

export { Resource, hasPermissionTo };

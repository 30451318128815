import { transformResponse } from '@/utils/common';

import { geebeeRootBaseApiWithTags } from '../base/geebeeApi';

import {
  AddFocusedInstituteRequestType,
  AddFocusedInstituteResponseType,
  DeleteFocusedInstituteRequestType,
  DeleteFocusedInstituteResponseType,
  GetFocusedInstituteRequestType,
  GetFocusedInstituteResponseType,
} from './type';

export const focusedInstituteApi = geebeeRootBaseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getFocusedInstitutes: builder.mutation<
      GetFocusedInstituteResponseType,
      GetFocusedInstituteRequestType
    >({
      query: (body) => ({
        url: `/focused-university/search`,
        body,
        method: `POST`,
      }),
      transformResponse,
    }),
    addFocusedInstitutes: builder.mutation<
      AddFocusedInstituteResponseType,
      AddFocusedInstituteRequestType
    >({
      query: (body) => ({ url: `/focused-university`, body, method: `POST` }),
      transformResponse,
    }),
    deleteFocusedInstitute: builder.mutation<
      DeleteFocusedInstituteResponseType,
      DeleteFocusedInstituteRequestType
    >({
      query: ({ id }) => ({
        url: `/focused-university/${id}`,
        method: `DELETE`,
      }),
      transformResponse,
    }),
  }),
});

export const {
  useGetFocusedInstitutesMutation,
  useAddFocusedInstitutesMutation,
  useDeleteFocusedInstituteMutation,
} = focusedInstituteApi;

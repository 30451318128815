import { isFalsy, StatusPillWithInfo } from '@leapfinance/frontend-commons';
import { Tooltip, Typography } from '@mui/material';
import { type GridColDef } from '@mui/x-data-grid';
import Link from 'next/link';

import { definitions } from '@/types/schema';

const HEADER_CLASSNAME = `bg-primary-200 border-r border-grey-200`;

import { Tags, TagVariants } from '@leapfinance/geebee-component-library';
import { format, parseISO } from 'date-fns';

import { UserConfig } from '@/types/user';
import { VASTypes } from '@/types/vas';
import { ADD_STUDENT_USER_TYPES } from '@/utils/constants';

const getDateInMonthDayFormat = (date: string) => {
  try {
    if (!isFalsy(date)) {
      return format(parseISO(date), `MMMM d, yyyy`);
    } else {
      return `N/A`;
    }
  } catch (err) {
    return `N/A`;
  }
};

function getVasStatusTagsStyling(status: string): TagVariants {
  switch (status) {
    case `PENDING`:
    case `REQUEST_INITIATED`:
    case `ON_HOLD`:
      return `warning`;
    case `CANCELLED`:
    case `CANNOT_BE_FULFILLED`:
    case `REJECTED`:
      return `error`;

    case `REQUEST_FULFILLED`:
    case `REQUEST_GUARANTEED`:
    case `APPROVED`:
    case `DISBURSED`:
    case `DONE`:
      return `success`;

    default:
      return `primary`;
  }
}

const getMappedVisaCounsellorsList = (
  visaTeamList?: definitions['UserDto'][],
) => {
  return (visaTeamList ?? [])
    ?.map((user) => {
      return `${user?.firstName} ${user?.lastName}`;
    })
    .join(`, `);
};

type CustomHeaderProps = {
  name: string;
};

const CustomHeader = ({ name }: CustomHeaderProps) => {
  return (
    <Typography
      variant="caption2"
      className="text-grey-800 whitespace-normal h-auto"
    >
      {name}
    </Typography>
  );
};

const getColumns = () => {
  const columns: GridColDef<definitions['VASRequestResponseDto']>[] = [
    {
      field: `requestId`,
      // headerName: `ID`,
      sortable: false,
      headerClassName: HEADER_CLASSNAME,
      renderCell(params) {
        return (
          <Link href={`/student/${params.row.studentId}?active=VAS`} passHref>
            <a target={`_blank`}>
              <Typography
                variant="body2"
                className="text-primary-500 cursor-pointer"
              >
                {params.row.requestId}
              </Typography>
            </a>
          </Link>
        );
      },
      renderHeader(params) {
        return <CustomHeader name="ID" />;
      },
    },
    {
      field: `studentName`,
      headerClassName: HEADER_CLASSNAME,
      sortable: false,
      renderCell(params) {
        return (
          <Typography variant="body2" className="text-grey-800">
            {params.row.studentName}
          </Typography>
        );
      },
      flex: 1,
      renderHeader(params) {
        return <CustomHeader name="Student Name" />;
      },
    },
    {
      field: `studentContactNumber`,
      sortable: false,
      headerClassName: HEADER_CLASSNAME,
      renderCell(params) {
        return (
          <Typography
            variant="body2"
            className="text-grey-800  hover:whitespace-normal hover:h-auto"
          >
            {`${params.row.countryCode}` + `${params.row.contactNumber}`}
          </Typography>
        );
      },
      renderHeader(params) {
        return <CustomHeader name="Contact Number" />;
      },
      flex: 1,
    },
    {
      field: `cspName`,
      headerClassName: HEADER_CLASSNAME,
      renderCell(params) {
        return (
          <Typography
            variant="body2"
            className="text-grey-800 hover:whitespace-normal hover:h-auto"
          >
            {params.row.cspName}
          </Typography>
        );
      },
      sortable: false,
      renderHeader(params) {
        return <CustomHeader name="CSP Name" />;
      },
      flex: 1,
    },
    {
      field: `serviceCategory`,
      headerClassName: HEADER_CLASSNAME,
      renderCell(params) {
        return (
          <Typography
            variant="subtitle2"
            className="text-grey-800 block hover:whitespace-normal hover:h-auto"
          >
            {params.row.serviceCategory}
          </Typography>
        );
      },
      sortable: false,
      renderHeader(params) {
        return <CustomHeader name="Request Type" />;
      },
      flex: 0.7,
    },
    {
      field: `universityName`,
      headerClassName: HEADER_CLASSNAME,
      renderCell(params) {
        return (
          <Typography variant="body2" className="text-grey-800">
            {params.row.universityName}
          </Typography>
        );
      },
      sortable: false,
      renderHeader(params) {
        return <CustomHeader name="University" />;
      },
      flex: 1,
    },
    {
      field: `status`,
      headerClassName: HEADER_CLASSNAME,
      renderCell(params) {
        return (
          <div className="w-full flex justify-center">
            <Tags
              title={params.row.statusLabel ?? ``}
              variant={getVasStatusTagsStyling(params.row.status ?? ``)}
              hasActiveBorder
              containerClasses={`max-w-fit w-[99%]  [&>span]:truncate`}
              size="medium"
            />
          </div>
        );
      },
      sortable: false,
      renderHeader(params) {
        return <CustomHeader name="Status" />;
      },
      flex: 1,
    },
    {
      field: `callBackRequestStatus`,
      headerClassName: HEADER_CLASSNAME,
      renderCell(params) {
        return params.row.callBackRequestStatusLabel ? (
          <StatusPillWithInfo
            status={params.row.callBackRequestStatus ?? ``}
            statusText={params.row.callBackRequestStatusLabel ?? ``}
          />
        ) : (
          <></>
        );
      },
      sortable: false,
      renderHeader(params) {
        return <CustomHeader name="Callback Request Status" />;
      },
      flex: 0.7,
    },
    {
      field: `createdAt`,
      headerClassName: HEADER_CLASSNAME,
      renderCell(params) {
        return (
          <Typography variant="body2" className="text-grey-800">
            {getDateInMonthDayFormat(params.row.createdAt ?? ``)}
          </Typography>
        );
      },
      renderHeader(params) {
        return <CustomHeader name="Created on" />;
      },
      sortable: false,
      flex: 0.7,
    },
    {
      field: `visaTeamList`,
      headerClassName: HEADER_CLASSNAME,
      renderCell(params) {
        return (
          <Tooltip
            title={
              isFalsy(params?.row?.visaTeamList)
                ? ``
                : getMappedVisaCounsellorsList(params?.row?.visaTeamList)
            }
            placement="top"
            arrow={true}
          >
            <Typography
              variant="body2"
              className="text-grey-800 whitespace-nowrap truncate"
            >
              {getMappedVisaCounsellorsList(params?.row?.visaTeamList)}
            </Typography>
          </Tooltip>
        );
      },
      renderHeader() {
        return <CustomHeader name="Mapped Visa Counsellors" />;
      },
      sortable: false,
      flex: 0.8,
    },
  ];

  return columns;
};

function getVasRequestFormTitle(type: VASTypes) {
  switch (type) {
    case `accomodation`:
      return `Request for Accomodation`;
    case `gic`:
      return `Request for GIC`;
    case `loan`:
      return `Raise Loan Request`;
    case `flywire`:
      return `Flywire Payment`;
    default:
      return ``;
  }
}

function formatFlywirePaymentLinkCreationDate(date: string) {
  try {
    // Format the parsed Date object into the desired format
    const formattedDate = format(new Date(date), `dd MMM yyyy 'at' HH:mm:ss`);
    return formattedDate;
  } catch (err) {
    return ``;
  }
}

function isStudentExisting(formValues: any) {
  return Object.values(formValues?.existingDuplicateStudent ?? {}).some(
    (item) => !isFalsy(item),
  );
}

const FLYWIRE_DETAILS_LOCAL_STORAGE_KEY = `flywireDetails`;

const getFlywireDetailsDataOnRedirect = (): {
  open?: boolean;
  paymentLink?: string;
} => {
  try {
    return JSON.parse(
      localStorage.getItem(FLYWIRE_DETAILS_LOCAL_STORAGE_KEY) ?? ``,
    );
  } catch (err) {
    return {};
  }
};

const getAddNewStudentButtonStyles = (
  user: UserConfig,
  shouldDisableStudentField: boolean,
) => {
  if (ADD_STUDENT_USER_TYPES?.includes(user?.userType as any)) {
    if (shouldDisableStudentField) {
      return `text-grey-400`;
    } else {
      return `text-primary-500`;
    }
  } else {
    return `hidden`;
  }
};

export {
  getColumns,
  getVasRequestFormTitle,
  isStudentExisting,
  getFlywireDetailsDataOnRedirect,
  formatFlywirePaymentLinkCreationDate,
  FLYWIRE_DETAILS_LOCAL_STORAGE_KEY,
  getAddNewStudentButtonStyles,
};

import { GBTypography } from '@leapfinance/geebee-component-library';
import { Avatar, Chip, Typography } from '@mui/material';
import startCase from 'lodash/startCase';
import { NextRouter } from 'next/router';

import { Roles } from '@/types/global';
import { UserConfig } from '@/types/user';
import { GB_REPRESENTATIVE } from '@/utils/constants';

export const getSpeedupProfileMenuOptions: (
  router: NextRouter,
  profileData: UserConfig | null,
  role: string | null,
  handleShowAddModal: () => void,
  userManagementVisibility: boolean,
  viewAllTaskPageVisibility: boolean,
) => Array<
  | {
      title?: string;
      children?: React.ReactNode;
      menuClasses?: string;
      menuAction?: () => void;
      addDivider?: boolean;
    }
  | false
> = (
  router,
  profileData,
  role,
  handleShowAddModal,
  userManagementVisibility,
  viewAllTaskPageVisibility,
) => [
  {
    title: (profileData?.firstName || ``) + ` ` + (profileData?.lastName || ``),
    children: (
      <>
        {` `}
        <Avatar className="text-md h-10 w-10 text-[#2F9ED5] bg-[#bddeee]">
          {(profileData?.firstName || ` `).substring(0, 1).toUpperCase() +
            `` +
            (profileData?.lastName || ` `).substring(0, 1).toUpperCase()}
        </Avatar>
        <div className="pl-4 w-full">
          <p className=" font-sans font-medium break-words overflow-hidden text-ellipsis w-[90%]">
            {(profileData?.firstName || ``) +
              ` ` +
              (profileData?.lastName || ``)}
          </p>
          <Chip
            label={startCase(role as string).split(` `)[1]}
            size="small"
            className="bg-orange-200 text-[0.5rem] h-auto p-1"
          />
        </div>
      </>
    ),
    menuClasses: `flex items-start w-full border-b border-red-500`,
    addDivider: true,
  },
  {
    title: profileData?.email || `-`,
    menuClasses: `flex flex-col items-start flex-wrap font-sans w-full`,
    children: (
      <>
        <p className="text-grey-600 text-sm">Email</p>
        <p className="text-primary-800 break-words overflow-hidden text-ellipsis w-[80%]">
          {profileData?.email || `-`}
        </p>
      </>
    ),
  },
  {
    children: (
      <p className="text-primary-800 hover:underline">Information Hub</p>
    ),
    menuClasses: `font-sans w-full hover:underline`,
    menuAction: () => router?.push(`/knowledge-library`),
  },
  {
    children: <p className="text-primary-800 hover:underline">My Downloads</p>,
    menuClasses: `font-sans w-full hover:underline`,
    menuAction: () => router.push(`/my-downloads`),
  },
  [Roles.counsellor, Roles.zonalManager, Roles.viewer].includes(
    role as Roles,
  ) && {
    children: (
      <>
        <p className="text-primary-800 hover:underline">Add Contacts</p>
        <span className="bg-gbTokens-teal-200 px-2 rounded-xl flex items-center gap-1 ml-2">
          <div className="bg-[#3C5B31] h-1 w-1 rounded-full"></div>
          <GBTypography variant="caption1">New</GBTypography>
        </span>
      </>
    ),
    menuClasses: `font-sans w-full hover:underline`,
    menuAction: () => handleShowAddModal(),
  },
  userManagementVisibility && {
    children: <p className="text-primary-800">User Management</p>,
    menuClasses: `font-sans w-full hover:underline`,
    menuAction: () => router.replace(`/user-management/users`),
  },
  {
    children: (
      <p className="text-primary-800">
        {role === GB_REPRESENTATIVE ? `CSP Management` : `CSP Information`}
      </p>
    ),
    menuClasses: `font-sans w-full hover:underline`,
    menuAction: () => router.replace(`/csp-management`),
  },
  viewAllTaskPageVisibility && {
    children: <p className="text-primary-800">View All Task</p>,
    menuClasses: `font-sans w-full hover:underline`,
    menuAction: () => router.replace(`/tasks/view-all`),
  },
  {
    children: (
      <Typography className="text-primary-800" variant="subtitle1">
        University Mails
      </Typography>
    ),
    menuClasses: `font-sans w-full hover:underline`,
    menuAction: () => router.replace(`/dashboard/admin`),
  },
  {
    children: (
      <Typography className="text-primary-800" variant="subtitle1">
        Partner Institutes
      </Typography>
    ),
    menuClasses: `font-sans w-full hover:underline`,
    menuAction: () => router.replace(`/institutes`),
  },
  {
    children: (
      <Typography className="text-primary-800" variant="subtitle1">
        Institute Mapping
      </Typography>
    ),
    menuClasses: `font-sans w-full hover:underline`,
    menuAction: () => router.replace(`/institute-mapping`),
  },
  {
    children: (
      <Typography className="text-primary-800" variant="subtitle1">
        Value Added Service
      </Typography>
    ),
    menuClasses: `font-sans w-full hover:underline`,
    menuAction: () => router.replace(`/vas/dashboard`),
  },
  {
    children: (
      <Typography className="text-primary-800" variant="subtitle1">
        SOP Assistance
      </Typography>
    ),
    menuClasses: `font-sans w-full hover:underline`,
    menuAction: () => router.replace(`/sop/dashboard`),
  },
  role === Roles.representative && {
    children: (
      <Typography className="text-primary-800" variant="subtitle1">
        Meeting Tracker
      </Typography>
    ),
    menuClasses: `font-sans w-full hover:underline`,
    menuAction: () => router.replace(`/meeting-tracker`),
  },
  role === Roles.representative && {
    children: (
      <Typography className="text-primary-800" variant="subtitle1">
        Lead Distribution Logic
      </Typography>
    ),
    menuClasses: `font-sans w-full hover:underline`,
    menuAction: () => router.replace(`/lead-distribution-rules-management`),
  },
  {
    children: (
      <Typography className="text-primary-800" variant="subtitle1">
        Vouchers Request Dashboard
      </Typography>
    ),
    menuClasses: `font-sans w-full hover:underline`,
    menuAction: () => router.replace(`/vouchers/dashboard`),
  },
  role === Roles.representative && {
    children: (
      <Typography className="text-primary-800" variant="subtitle1">
        Focused Institutes
      </Typography>
    ),
    menuClasses: `font-sans w-full hover:underline`,
    menuAction: () => router.replace(`/focused-institutes`),
  },
  {
    children: <p className="text-error-500">Logout</p>,
    menuClasses: `font-sans w-full`,
    menuAction: () => window?.open(`/api/logout`, `_self`),
  },
];
